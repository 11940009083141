<script lang="ts">
  import { appContext } from "@client/store";
  import { Toast } from "flowbite-svelte";
  import { slide } from "svelte/transition";
  $: notifications = appContext.notifications;
</script>

<Toast
  transition={slide}
  open={$notifications !== null}
  params={{ duration: 250, delay: 0 }}
  dismissable={true}
  color="orange"
  on:close={() => ($notifications = null)}
  position="none"
  divClass="rounded-lg flex items-center w-full shadow text-gray-400 bg-gray-800 gap-3 p-2 z-50 w-auto z-50 w-auto border-2 border-orange-700"
>
  <div class="font-semibold text-green-500">
    {$notifications?.title ?? "Hey listen!"}
  </div>
  <div class="text-orange-700">
    {$notifications?.message}
  </div>
</Toast>
